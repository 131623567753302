<style>
.nounExplanation {
  width: 300px;
  /* height: 50px; */
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: fixed;
  top: 280px;
  /* left:0px; */
  left: 50px;
  background-color: papayawhip;
  z-index: 999;
}
</style>

<template>
  <div>
    <template>
      <p class="title p-t-5">查询周期</p>
      <Row :gutter="8">
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="开始时间"
            v-model="chkStartDate2"
            size="small"
            clearabled="false"
          ></DatePicker>
        </i-col>
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="结束时间"
            size="small"
            v-model="chkEndDate2"
            placement="bottom-end"
            clearabled="false"
          ></DatePicker>
        </i-col>

      </Row>
    </template>

    <p class="title p-t-5">公司列表</p>
    <Row>
      <i-col span="24">
        <Select v-model="currentCompanyId" size="small">
          <Option value="0" v-if="companyArray.length > 1">全部</Option>
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>
    </Row>

    <p class="title p-t-5">关键字</p>
    <Row :gutter="8">
      <i-col span="18">
        <i-input
          v-model="keyword"
          type="text"
          size="small"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { getCompanyChild } from '@/api/os/company'
import { ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return {
      loadSearch: false,

      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 0, name: '未对账' },
        { id: 1, name: '已对账' }
      ],
      years: [],
      seasons: [1, 2, 3, 4],
      months: [],
      chooseYear: '-1',
      chooseSenson: '-1',
      chooseMonth: '-1',

      companyArray: [],
      keyword: '',
      currentCompanyId: '0',
      executeStatus: -1,
      startDate: '',
      endDate: '',
      chkStartDate: '',
      chkEndDate: '',
      chkStartDate2: new Date().getFullYear() + '-01-01',
      chkEndDate2: new Date().getFullYear() + '-12-31'
    }
  },
  created () {
    this.initCompanyArray()
  },
  methods: {

    initCompanyArray () {
      const that = this
      // 获取交易方公司列表
      getCompanyChild({
        companyId: that.companyId,
        type:
        that.$store.getters.token.userInfo.publisherId === that.companyId
          ? 1
          : 2,
        childType: 2,
        companyType: 1
      }).then((res) => {
        let array = [
          {
            id: res.companyId,
            name: res.companyName
          }
        ]
        if (res.children.length) {
          array = array.concat(
            that.formatCompanyArray(res.children).filter(
              (x) => x.id !== res.companyId
            )
          )
        }
        that.companyArray = array
        if (res.length > 1) {
          that.currentCompanyId = '0'
        } else {
          that.currentCompanyId = that.companyId
        }

        that.search()
      })
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },
    search () {
      this.$store.commit(
        'set_billDetails_transactionReconciliationQuery',
        this.generateCondition
      )
      // this.$store.commit('set_billDetails_pageType', 'List')
      this.$store.commit('set_billDetails_update', new Date())
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    generateCondition () {
      // 获取查询条件
      const query = {
        companyId: this.currentCompanyId,
        queryString: this.keyword
      }
      query.startDate = ParseDate(this.chkStartDate2)
      query.endDate = ParseDate(this.chkEndDate2)

      return query
    }
  },
  watch: {
  }
}
</script>
